import React from "react"
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import Layout from "../components/layout.js"
import SEO from "../components/seo"

const Blog = ({ data: { allWordpressPost, pinkDiamond } }) => {
  return (
    <Layout>
      <SEO title="Allan Kiezel's Blog" description="" />
      <div
        className={
          "bg-blue-50 bg-no-repeat bg-hero-hp bg-blog-p lg:bg-blog-p-lg xl:bg-blog-p-xl bg-blog-s md:bg-blog-s-lg xl:bg-blog-s-xl py-32 sm:py-36 lg:py-48 px-8 sm:px-28 lg:px-28 xl:px-60 relative"
        }
      >
        <div
          className={
            "container mx-auto lg:mx-0 max-w-lg lg:max-w-lg xl:max-w-xl"
          }
        >
          <div className={"font-serif text-black text-2xl pb-2 xl:pb-3"}>
            Allan Kiezel's
          </div>
          <h1
            className={"font-serif text-pink text-4xl sm:text-5xl lg:text-7xl"}
          >
            Latest Blog Posts
          </h1>
        </div>
        <img
          src={pinkDiamond.publicURL}
          className={"absolute -left-7 -bottom-10 sm:-bottom-14 w-20 sm:w-28"}
        />
      </div>
      <div className={"container mx-auto max-w-4xl pt-16 px-6 divide-y-1"}>
        {allWordpressPost.edges.map(({ node }) => (
          <div className="py-8" key={node.id}>
            <div className={"pb-2 text-gray-800"}>{node.date}</div>
            <h2 className={"text-3xl"}>
              <Link
                to={`/${node.slug}`}
                dangerouslySetInnerHTML={{ __html: node.title }}
              ></Link>
            </h2>
            <div
              className={"pt-4"}
              dangerouslySetInnerHTML={{ __html: node.excerpt }}
            ></div>
            <Link
              to={`/${node.slug}`}
              className="bg-green hover:bg-green-dark text-white py-4 px-10 mt-6 rounded inline-block"
            >
              Continue Reading
            </Link>
          </div>
        ))}
      </div>
    </Layout>
  )
}

Blog.defaultProps = {
  title: null,
  slug: null,
  date: null,
  excerpt: null,
}

Blog.propTypes = {
  title: PropTypes.string,
  slug: PropTypes.string,
  date: PropTypes.string,
  excerpt: PropTypes.string,
}

export default Blog

export const query = graphql`
  {
    allWordpressPost {
      edges {
        node {
          id
          slug
          title
          date(formatString: "MMMM D, YYYY")
          excerpt
        }
      }
    }
    pinkDiamond: file(relativePath: { eq: "bg/pink-diamond.svg" }) {
      publicURL
    }
  }
`
